import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import BannerSlider from "../../organisms/banner-slider/bannerSlider";
import NewProductCard from "../../atoms/new-product-card/newProductCard";

import "./homeTemplate.scss";

const HomeTemplate = () => {
  const data = useStaticQuery(graphql`
		query QUERY_HOME {
			allContentfulBannerSlider {
        nodes {
          items {
            mainText
            secondaryText {
              raw
            }
            centeredText
            background {
              file {
                fileName
                url
              }
            }
          }
          page
        }
      }
      allContentfulDestacadoHome {
        nodes {
          background {
            file {
              url
            }
          }
          description {
            raw
          }
          href
          redirectionText
          subtitle
          titleText
        }
      }
      allContentfulProductosHome {
        nodes {
          buttonText
          buttonHref
          products {
            productName
            productImage {
              file {
                url
              }
            }
            href
          }
        }
      }
      allContentfulNovedadesProductos {
        nodes {
          textTitle
          products {
            href
            productImage {
              file {
                url
              }
            }
            productName
          }
        }
      }
		}
  `);

  const bannerData = data.allContentfulBannerSlider.nodes.filter(banner => (
    banner.page === "Inicio"
  ));

  const prominentData = data.allContentfulDestacadoHome.nodes[0];

  const productCategories = data.allContentfulProductosHome.nodes[0];

  const productNews = data.allContentfulNovedadesProductos.nodes[0];

  return (
    <div className="t-home">
      <BannerSlider items={bannerData[0].items} />
      <section className="t-home__product-categories">
        <div className="t-home__product-categories__products">
          {productCategories.products.map((product) => (
            <a
              key={product.productName}
              className="t-home__product-categories__products__product"
              href={product.href}
            >
              <img
                className="t-home__product-categories__products__product__image"
                src={product.productImage.file.url}
                alt={`Producto ${product.productName}`}
              />
              <h3 className="t-home__product-categories__products__product__name">
                {product.productName}
              </h3>
            </a>
          ))}
        </div>
        <div className="t-home__product-categories__button-container">
          <a
            className="t-home__product-categories__button-container__button"
            href={productCategories.buttonHref}
          >
            {productCategories.buttonText}
          </a>
        </div>
      </section>
      <section className="t-home__news">
        <h2 className="t-home__news__title">{productNews.textTitle}</h2>
        <div className="t-home__news__products">
          {productNews.products.map((product) => (
            <div
              key={product.productName}
              className="t-home__news__products__product"
            >
              <NewProductCard {...product} />
            </div>
          ))}
        </div>
      </section>
      <section className="t-home__prominent">
        <img
          className="t-home__prominent__background"
          alt="Fondo destacado"
          src={prominentData.background.file.url}
        />
        <div className="t-home__prominent__black-cover"></div>
        <div className="t-home__prominent__text">
          <p className="t-home__prominent__text__title">{prominentData.titleText}</p>
          <p className="t-home__prominent__text__subtitle">{prominentData.subtitle}</p>
          <p className="t-home__prominent__text__desc">
            {JSON.parse(prominentData.description.raw).content[0].content[0].value}
          </p>
          <div className="t-home__prominent__text__footer">
            <a className="t-home__prominent__text__footer__text" href={prominentData.href}>
              {prominentData.redirectionText}
            </a>
            <a className="icon-chevron-right" href={prominentData.href} />
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeTemplate;