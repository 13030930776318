import React from "react";
import Layout from "../components/organisms/layout/layout";
import HomeTemplate from "../components/templates/home-template/homeTemplate";

const Home = () => {
  return (
    <Layout title="Dicar | Inicio">
      <HomeTemplate />
    </Layout>
  )
}

export default Home;